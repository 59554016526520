

















import { defineComponent, ref, watch } from '@vue/composition-api';
import { download, upload, resizeImage } from '@/admin/file';
import { useNotification } from '@/composition/notification';

export interface HTMLElementEvent<T extends HTMLElement> extends Event {
  target: T;
}
export default defineComponent({
  props: {
    value: {
      type: String,
      default: '',
      required: true,
    },
    isNotResize: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const notification = useNotification();
    const src = ref('');
    if (props.value) {
      download(props.value).then((signedUrl) => {
        src.value = signedUrl;
      });
    }
    const isUploading = ref(false);
    const fileInput = ref();
    const removeImage = () => {
      src.value = '';
      context.emit('input', '');
    };
    const onFocus = () => fileInput.value.click();
    const onFileChange = async (e: HTMLElementEvent<HTMLInputElement>) => {
      const files = e.target.files || ([] as File[]);
      if (files.length === 0) return;
      if (!['image/png', 'image/jpeg', 'image/gif', 'image/svg+xml'].includes(files[0].type)) {
        notification.error('画像（png, jpeg, jpg, gif, svg）しかアップロードできません');
        return;
      }
      isUploading.value = true;
      const filePath = await upload(files[0]);
      if (!props.isNotResize && ['image/png', 'image/jpeg'].includes(files[0].type)) await resizeImage(filePath);
      src.value = await download(filePath);
      context.emit('input', filePath);
      isUploading.value = false;
    };

    watch(
      () => props.value,
      () => {
        if (props.value) {
          download(props.value).then((signedUrl) => {
            src.value = signedUrl;
          });
        } else {
          src.value = '';
        }
      }
    );

    return { removeImage, onFocus, onFileChange, fileInput, src, isUploading };
  },
});
