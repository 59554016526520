import core from '@/admin/core';
import { reactive } from '@vue/composition-api';
import { DisplayDate } from '@/admin/util';
import myAttributes from '@/composition/myAttributes';

export const MaxProductImagesCount = 19;
const MaxMaxSalesQuantityPerUser = 100;

interface Permission {
  acceptAllMembers: boolean;
  acceptAllUsers: boolean;
  deny: boolean;
  planIds?: string[];
  seasonIds?: number[];
}

interface Interval {
  interval: 'day' | 'week' | 'month' | 'year';
  intervalCount: number;
}

export interface ProductResponse {
  productId: string;
  createDate: number;
  productType?: 'consumable' | 'entitlement';
  productName: string;
  description: string;
  annotation: string;
  postPurchaseText: string;
  linkText: string;
  linkUrl: string;
  isExternalLink: boolean;
  category: string[];
  thumbnail: string;
  images?: string[];
  priceId: string;
  price: number;
  purchaseStartDate: number;
  purchaseEndDate: number;
  accessStartDate: number;
  accessEndDate: number;
  purchaseAuthority: Permission;
  expirationType?: 'date' | 'period';
  expirationDate?: number;
  expirationPeriod?: Interval;
  maxSalesQuantityPerUser?: number;
  stockSettings?: {
    maxSalesQuantity: number;
    isDisplayRemainingQuantity: boolean;
    thresholdOfRemainingQuantity: number;
    salesQuantity: number;
  };
  deliveryMonths?: number;
  isDeleted: boolean;
  isArchive: boolean;
  isRequiredShippingAddress: boolean;
}

export interface ProductsResponse extends ProductResponse {
  accessPeriodStatus: string;
  purchasePeriodStatus: string;
}

export interface ProductProperty {
  productId: string;
  createDate?: DisplayDate;
  productType?: 'consumable' | 'entitlement';
  productName: string;
  description: string;
  annotation: string;
  postPurchaseText: string;
  linkText?: string;
  linkUrl?: string;
  isExternalLink?: boolean;
  category: string;
  thumbnail: string;
  images?: string[];
  priceId: string;
  price: number;
  purchaseStartDate: DisplayDate;
  purchaseEndDate: DisplayDate;
  accessStartDate: DisplayDate;
  accessEndDate: DisplayDate;
  purchaseAuthority: Permission;
  expirationType?: 'date' | 'period';
  expirationDate: DisplayDate;
  expirationPeriod?: Interval;
  url?: string;
  isArchive: boolean;
  maxSalesQuantityPerUser?: number;
  stockSettings?: {
    maxSalesQuantity: number;
    isDisplayRemainingQuantity: boolean;
    thresholdOfRemainingQuantity: number;
    salesQuantity: number;
  };
  deliveryMonths?: number;
  isRequiredShippingAddress: boolean;
}

interface ProductsProperty extends ProductProperty {
  accessPeriodStatus: string;
  purchasePeriodStatus: string;
}

// 個別課金商品と画像・動画商品の型の違い
// postPurchaseText: 個別課金商品のみ
// category: 個別課金商品のみ
// displayNote: 画像・動画販売商品のみ
// digitalContentType: 画像・動画販売商品のみ
// sendingOfficialUserId: 画像・動画販売商品のみ
// stockSettingsのisDisplayRemainingQuantityとthresholdOfRemainingQuantity: 画像・動画販売商品のみ

interface ProductProps {
  productType: 'consumable' | 'entitlement';
  productName: string;
  description: string;
  annotation: string;
  postPurchaseText: string;
  linkText?: string;
  linkUrl?: string;
  isExternalLink?: boolean;
  category: string[];
  thumbnail: string;
  images?: string[];
  price: number;
  purchaseStartDate: number;
  purchaseEndDate?: number;
  accessStartDate: number;
  accessEndDate?: number;
  purchaseAuthority: Permission;
  expirationType?: 'date' | 'period';
  expirationDate?: number;
  expirationPeriod?: Interval;
  maxSalesQuantityPerUser?: number;
  stockSettings?: {
    maxSalesQuantity: number;
    isDisplayRemainingQuantity: boolean;
    thresholdOfRemainingQuantity: number;
    salesQuantity: number;
  };
  deliveryMonths?: number;
  isRequiredShippingAddress: boolean;
}

export const getProducts = async () => {
  if (myAttributes.myRequestPermissions?.products) {
    try {
      const result = await core.httpClient.get('/admin/public/products');
      return result.data as ProductsResponse[];
    } catch (e) {
      return [];
    }
  } else return [];
};

export const getDigitalContentProducts = async () => {
  if (myAttributes.myRequestPermissions?.products) {
    try {
      const result = await core.httpClient.get('/admin/public/digitalContentProducts');
      // V2移行前にレポートに出したくV2で実装し直すため、一部プロパティが違うが型は個別課金商品に合わせておく
      return result.data.digitalContentProducts as ProductsResponse[];
    } catch (e) {
      return [];
    }
  } else return [];
};

export class Products {
  fetchLoading = false;
  items: ProductsProperty[] = [];

  constructor() {
    this.init();
  }
  init = async () => {
    this.fetchLoading = true;
    this.items = await this.getProducts();
    this.fetchLoading = false;
  };
  getProducts = async (): Promise<ProductsProperty[]> => {
    const _items = await getProducts();
    return _items
      .map((_item) => {
        const item: ProductsProperty = {
          productId: _item.productId,
          createDate: new DisplayDate(_item.createDate),
          productName: _item.productName,
          productType: _item.productType,
          description: _item.description,
          annotation: _item.annotation,
          postPurchaseText: _item.postPurchaseText,
          linkText: _item.linkText,
          linkUrl: _item.linkUrl,
          isExternalLink: _item.isExternalLink,
          category: _item.category.join(','),
          thumbnail: _item.thumbnail,
          images: _item.images,
          priceId: _item.priceId,
          price: _item.price,
          purchaseStartDate: new DisplayDate(_item.purchaseStartDate),
          purchaseEndDate: new DisplayDate(_item.purchaseEndDate),
          accessStartDate: new DisplayDate(_item.accessStartDate),
          accessEndDate: new DisplayDate(_item.accessEndDate),
          maxSalesQuantityPerUser: _item.maxSalesQuantityPerUser,
          purchaseAuthority: _item.purchaseAuthority,
          expirationType: _item.expirationType,
          expirationPeriod: _item.expirationPeriod,
          url: `/#/purchase/${_item.productId}`,
          expirationDate: new DisplayDate(_item.expirationDate),
          stockSettings: _item.stockSettings,
          deliveryMonths: _item.deliveryMonths,
          isArchive: _item.isArchive,
          accessPeriodStatus: _item.accessPeriodStatus,
          purchasePeriodStatus: _item.purchasePeriodStatus,
          isRequiredShippingAddress: _item.isRequiredShippingAddress,
        };
        if (_item.expirationType) item.expirationType = _item.expirationType;
        if (_item.expirationPeriod) item.expirationPeriod = _item.expirationPeriod;
        return item;
      })
      .sort((a, b) => (b.createDate?.value && a.createDate?.value ? b.createDate.value - a.createDate.value : -1));
  };
  deleteProduct = async (productId: string) => {
    await core.httpClient.delete(`/admin/public/products/${productId}`);
  };
}

export class Product {
  productId = '';
  fetchLoading = false;
  isAccessEnd = false;
  isPurchaseEnd = false;
  isPutButton = false;
  isUseStartDate = false;
  isUseEndDate = false;
  buttonProperty = {
    linkText: '購入した商品を見る',
    linkUrl: '',
    isExternalLink: false,
  };
  isExpiration = false;
  isMaxSalesQuantityPerUser = false;
  isStockSettings = false;
  expirationProperty: {
    expirationType: 'date' | 'period';
    expirationDate: DisplayDate;
    expirationPeriod: Interval;
  } = {
    expirationType: 'date',
    expirationDate: new DisplayDate(),
    expirationPeriod: {
      interval: 'day',
      intervalCount: 1,
    },
  };
  item: ProductProperty = {
    productId: '',
    productType: 'entitlement',
    productName: '',
    description: '',
    annotation: '',
    postPurchaseText: '',
    category: '',
    thumbnail: '',
    images: undefined,
    priceId: '',
    price: 0,
    purchaseStartDate: new DisplayDate(),
    purchaseEndDate: new DisplayDate(),
    accessStartDate: new DisplayDate(),
    accessEndDate: new DisplayDate(),
    expirationDate: new DisplayDate(),
    maxSalesQuantityPerUser: undefined,
    purchaseAuthority: {
      acceptAllMembers: false,
      acceptAllUsers: false,
      deny: false,
      planIds: [],
      seasonIds: [],
    },
    stockSettings: {
      maxSalesQuantity: 0,
      isDisplayRemainingQuantity: false,
      thresholdOfRemainingQuantity: 0,
      salesQuantity: 0,
    },
    deliveryMonths: undefined,
    isArchive: false,
    isRequiredShippingAddress: false,
  };
  props: ProductProps | null = null;

  constructor(productId?: string) {
    if (productId) {
      this.productId = productId;
      this.init();
    }
  }

  init = async () => {
    this.fetchLoading = true;
    this.item = await this.getProduct(this.productId);
    this.fetchLoading = false;
  };

  getProduct = async (productId: string): Promise<ProductProperty> => {
    if (myAttributes.myRequestPermissions?.products) {
      const result = await core.httpClient.get(`/admin/public/products/${productId}`);
      const _item = result.data as ProductResponse;
      const item: ProductProperty = {
        productId: _item.productId,
        productType: _item.productType,
        productName: _item.productName,
        description: _item.description,
        annotation: _item.annotation,
        postPurchaseText: _item.postPurchaseText,
        linkText: _item.linkText,
        linkUrl: _item.linkUrl,
        isExternalLink: _item.isExternalLink,
        category: _item.category.join(','),
        thumbnail: _item.thumbnail,
        images: _item.images,
        priceId: _item.priceId,
        price: _item.price,
        purchaseStartDate: new DisplayDate(_item.purchaseStartDate),
        accessStartDate: new DisplayDate(_item.accessStartDate),
        purchaseAuthority: _item.purchaseAuthority,
        accessEndDate: new DisplayDate(_item.accessEndDate),
        purchaseEndDate: new DisplayDate(_item.purchaseEndDate),
        expirationDate: new DisplayDate(_item.expirationDate),
        maxSalesQuantityPerUser: _item.maxSalesQuantityPerUser,
        stockSettings: this.item.stockSettings,
        deliveryMonths: _item.deliveryMonths,
        isArchive: _item.isArchive,
        isRequiredShippingAddress: _item.isRequiredShippingAddress,
      };
      if (_item.accessEndDate) {
        this.isAccessEnd = true;
      }
      if (_item.purchaseEndDate) {
        this.isPurchaseEnd = true;
      }
      if (_item.expirationType) {
        this.isExpiration = true;
        this.expirationProperty.expirationType = _item.expirationType;
        this.expirationProperty.expirationPeriod = _item.expirationPeriod || this.expirationProperty.expirationPeriod;
        this.expirationProperty.expirationDate = new DisplayDate(_item.expirationDate);
      }
      if (_item.linkText) {
        this.isPutButton = true;
        this.buttonProperty.linkText = _item.linkText;
        this.buttonProperty.linkUrl = _item.linkUrl;
        this.buttonProperty.isExternalLink = _item.isExternalLink;
      }
      if (_item.maxSalesQuantityPerUser) {
        this.isMaxSalesQuantityPerUser = true;
      }
      if (_item.stockSettings) {
        this.isStockSettings = true;
        item.stockSettings = _item.stockSettings;
      }
      return item;
    } else return {} as ProductProperty;
  };

  setCopyProduct = (_item: ProductProperty) => {
    this.item.productType = _item.productType;
    this.item.productName = `【アイテム複製】${_item.productName}`;
    this.item.description = _item.description;
    this.item.annotation = _item.annotation;
    this.item.postPurchaseText = _item.postPurchaseText;
    this.item.linkText = _item.linkText;
    this.item.linkUrl = _item.linkUrl;
    this.item.isExternalLink = _item.isExternalLink;
    this.item.category = _item.category;
    this.item.thumbnail = _item.thumbnail;
    this.item.priceId = _item.priceId;
    this.item.price = _item.price;
    this.item.purchaseStartDate = new DisplayDate();
    this.item.accessStartDate = new DisplayDate();
    this.item.purchaseAuthority = _item.purchaseAuthority;
    this.item.stockSettings = _item.stockSettings;
    this.item.deliveryMonths = _item.deliveryMonths;
    if (_item.accessEndDate.value) {
      this.isAccessEnd = true;
      this.item.accessEndDate = _item.accessEndDate;
    }
    if (_item.purchaseEndDate.value) {
      this.isPurchaseEnd = true;
      this.item.purchaseEndDate = _item.purchaseEndDate;
    }
    if (_item.expirationType) {
      this.isExpiration = true;
      this.expirationProperty.expirationType = _item.expirationType;
      this.expirationProperty.expirationPeriod = _item.expirationPeriod || this.expirationProperty.expirationPeriod;
      this.expirationProperty.expirationDate = _item.expirationDate
        ? _item.expirationDate
        : this.expirationProperty.expirationDate;
    }
    if (_item.linkText) {
      this.isPutButton = true;
      this.buttonProperty.linkText = _item.linkText;
      this.buttonProperty.linkUrl = _item.linkUrl || '';
      this.buttonProperty.isExternalLink = _item.isExternalLink || false;
    }
    if (_item.maxSalesQuantityPerUser) {
      this.isMaxSalesQuantityPerUser = true;
      this.item.maxSalesQuantityPerUser = _item.maxSalesQuantityPerUser;
    }
    if (_item.stockSettings) {
      this.isStockSettings = true;
    }
    if (_item.deliveryMonths) {
      this.item.deliveryMonths = _item.deliveryMonths;
    }
  };

  createProps = () => {
    if (
      !this.item.productName ||
      !this.item.thumbnail ||
      !this.item.price.toString().match(/^\d+$/) ||
      this.item.purchaseStartDate.value === undefined ||
      this.item.accessStartDate.value === undefined
    )
      throw '商品名，料金，サムネイル, 開始日で未入力があります';
    else if (this.item.images && this.item.images.length > MaxProductImagesCount) {
      throw '19枚を超える追加画像は設定出来ません';
    } else if (0 < this.item.price && this.item.price < 50) throw '50円未満の商品は作成できません';
    else if (this.isPutButton && (!this.buttonProperty.linkText || !this.buttonProperty.linkUrl)) {
      throw 'ボタンを配置する場合はURLとボタンテキストが必須です';
    } else if (
      this.isPutButton &&
      !this.buttonProperty.linkUrl.match(/^(https?|ftp)(:\/\/[-_.!~*'()a-zA-Z0-9;/?:@&=+$,%#]+)$/)
    ) {
      throw 'リンクが不正です。ドメインから指定してください';
    } else if (
      !this.item.purchaseAuthority.acceptAllMembers &&
      !this.item.purchaseAuthority.planIds?.length &&
      !this.item.purchaseAuthority.seasonIds?.length
    )
      throw '購入権限を指定してください';
    else if (
      (this.isExpiration &&
        this.expirationProperty.expirationType === 'date' &&
        this.expirationProperty.expirationDate.value === undefined) ||
      (this.isAccessEnd && this.item.accessEndDate.value === undefined) ||
      (this.isPurchaseEnd && this.item.purchaseEndDate.value === undefined)
    ) {
      throw '不正な日時です';
    } else if (
      this.isExpiration &&
      this.expirationProperty.expirationType === 'date' &&
      this.expirationProperty.expirationDate.value &&
      (this.expirationProperty.expirationDate.value <= this.item.purchaseStartDate.value ||
        (this.isPurchaseEnd &&
          this.item.purchaseEndDate.value &&
          this.expirationProperty.expirationDate.value <= this.item.purchaseEndDate.value))
    ) {
      throw '購入可能期間は有効期限内にしてください';
    } else if (
      (!this.isPurchaseEnd &&
        !this.isAccessEnd &&
        this.item.accessStartDate.value > this.item.purchaseStartDate.value) ||
      (this.isPurchaseEnd &&
        this.isAccessEnd &&
        this.item.accessEndDate.value &&
        this.item.purchaseEndDate.value &&
        this.item.accessStartDate.value > this.item.purchaseStartDate.value &&
        this.item.accessEndDate.value < this.item.purchaseEndDate.value)
    ) {
      throw '購入可能期間は閲覧期限以内にしてください';
    } else if (!this.isPurchaseEnd && this.isAccessEnd) {
      // 購入終了日がなくて閲覧終了日がある場合は、閲覧できないけど購入できる期間が発生するのでエラーとする
      throw '購入終了日は閲覧終了日以前で設定してください';
    } else if (this.isMaxSalesQuantityPerUser && !this.item.maxSalesQuantityPerUser) {
      throw '一人当たりの最大購入可能数を確認してください';
    } else if (this.item.maxSalesQuantityPerUser && this.item.maxSalesQuantityPerUser > MaxMaxSalesQuantityPerUser) {
      throw '一人当たりの最大購入可能数は100より大きい値を設定できません。制限をかけたくない場合は入力を行わずに保存を行なってください';
    } else if (this.item.deliveryMonths === undefined) {
      throw 'お届けまでの目安を設定してください';
    } else if (this.item.deliveryMonths === 0 && !this.isPutButton) {
      throw '即時発行のデジコン商品を選択の場合、「ボタン」項目の設定は必須です';
    } else if (
      this.isStockSettings &&
      this.item.stockSettings &&
      (!this.item.stockSettings.maxSalesQuantity ||
        this.item.stockSettings.thresholdOfRemainingQuantity > this.item.stockSettings.maxSalesQuantity)
    ) {
      throw '在庫設定を確認してください';
    } else {
      this.props = {
        productType: this.item.productType || 'entitlement',
        productName: this.item.productName,
        description: this.item.description,
        annotation: this.item.annotation,
        postPurchaseText: this.item.postPurchaseText,
        category: this.item.category
          .split(',')
          .map((category) => category.trim())
          .filter((category) => !!category),
        thumbnail: this.item.thumbnail,
        images: this.item.images,
        price: this.item.price,
        accessStartDate: this.item.accessStartDate.value,
        purchaseAuthority: this.item.purchaseAuthority,
        purchaseStartDate: this.item.purchaseStartDate.value,
        deliveryMonths: this.item.deliveryMonths,
        isRequiredShippingAddress: this.item.isRequiredShippingAddress,
      };
      if (this.isAccessEnd && this.item.accessEndDate.value) {
        this.isAccessEnd = true;
        this.props.accessEndDate = this.item.accessEndDate.value;
      }
      if (this.isPurchaseEnd && this.item.purchaseEndDate.value) {
        this.isPurchaseEnd = true;
        this.props.purchaseEndDate = this.item.purchaseEndDate.value;
      }
      if (this.isPutButton) {
        this.props.linkText = this.buttonProperty.linkText;
        this.props.linkUrl = this.buttonProperty.linkUrl;
        this.props.isExternalLink = this.buttonProperty.isExternalLink;
      }
      if (this.isExpiration && this.expirationProperty.expirationDate) {
        this.props.expirationType = this.expirationProperty.expirationType;
        this.props.expirationPeriod = this.expirationProperty.expirationPeriod;
        this.props.expirationDate = this.expirationProperty.expirationDate.value;
      }
      if (this.item.productType === 'consumable' && this.isMaxSalesQuantityPerUser) {
        this.props.maxSalesQuantityPerUser = this.item.maxSalesQuantityPerUser;
      }
      if (this.isStockSettings && this.item.stockSettings) {
        this.props.stockSettings = {
          maxSalesQuantity: this.item.stockSettings.maxSalesQuantity,
          thresholdOfRemainingQuantity: this.item.stockSettings.thresholdOfRemainingQuantity,
          isDisplayRemainingQuantity: this.item.stockSettings.isDisplayRemainingQuantity,
          salesQuantity: this.item.stockSettings.salesQuantity,
        };
      }
    }
  };

  saveProduct = async (productId: string) => {
    this.createProps();
    if (!this.props) return;
    await core.httpClient.put(`/admin/public/products/${productId}`, this.props);
  };

  createProduct = async () => {
    this.createProps();
    if (!this.props) return;
    await core.httpClient.post('/admin/public/products', this.props);
  };
}

export const useProducts = () => {
  const products = new Products();
  return { products: reactive(products) };
};

export const useProduct = (productId?: string) => {
  const product = new Product(productId);
  return { product: reactive(product) };
};
